import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/zeit/4cd8a442/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "maths"
    }}>{`Maths`}</h1>
    <p>{`I am interested in the history of mathematics, mathematical curiosities and gadgets.`}</p>
    <p>{`I use `}<a parentName="p" {...{
        "href": "https://katex.org/docs/supported.html"
      }}>{`KaTeX`}</a>{` for rendering mathematics throughout the site.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      